// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Styles
	//////////////////////////////
	
	@include breakpoint(medium, max) {
		background: $secondary;
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: rem($menu-bar);
		transform: translateX(-100%);
		transition: .6s;
		width: map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width: 100%;
	}

	.navi-main {

		 li {
        display: block;
        border-top: rgba(75,75,74,0.8) solid rem(1px);
        font-weight:500;

        a, span {
            color:$primary;
            text-decoration: none;
            padding: .6rem 0rem .7rem 1rem;
            display: block;
            transition: .4s;

            
            &.active, &:hover {
                text-decoration: none;
                background: $medium;
                transition: .4s;
                color: $primary;
            }
        }

        &:last-child {
            border-right: none;
        }

        &.active {
            span {
                text-decoration: none;
               	background: $medium; 
            	transition: .4s;
                color: $primary;
            }
        }

		.sub {

			li {
                background:url(../images/icons/icon-li-sub.png) no-repeat;
			}

			a {
                padding-left:rem(30px);
                padding: .6rem 0rem .7rem 2.5rem;
                

            &.active, &:hover {
                background:url(../images/icons/icon-li-sub.png) no-repeat $medium; 
                text-decoration: none;    
                color: $primary;   
            }
			}


		}

       &.hasSub {
            .tapHover {
                &.active, &:hover{
                color:$primary;
             }
          }   
      }


	@include breakpoint(medium) {
         display:none;
         }

	}
	}

	@include breakpoint(medium) {
		display:none;
	}
}


// Navi TOP
//////////////////////////////
.navi-top {

			display:none;

	@include breakpoint(medium) {
		display:block;
		right:rem(0px);
		top:rem(70px);
		position: absolute;
		z-index:1000;
		
        li {
            float: left;
            font-size:$h6-size;
            margin:rem(0px) rem(0px);
            
            &:hover {

                a {
                    color:$primary;
                    font-weight:600;
                }
            }

            &.active {

                a {
                    color:$primary;
                    padding: rem(0px) rem(12px);    
                    font-weight:600;
                }
            }

            a {
                text-decoration: none;
                border-right: $border rem(1px) solid;
                display: block;
                text-align: center;
                color:$primary;
            }

           
            .home{
                width: rem(90px);
            }

            .leistungen{
                width: rem(130px);
            }


            .materialien{
                width: rem(130px);
            }

             .unternehmen{
                width: rem(158px);
            }

           .kontakt{
            border-right: none;
            width:rem(105px);
        }

            &.hasSub {
                height: auto;
                position: relative;               
                border-right: $border rem(1px) solid;
                width: rem(105px);
                text-align: center;

            &.active, &:hover {
                text-decoration: none;                                
                color: $primary; 
                font-weight:600;
                } 

            .projekte{
                background: url(../images/icons/li-sub.png) center bottom no-repeat; 
                padding-bottom:rem(12px);
                }

            }

                &.hovering, &:hover {
                height:auto;

                    ul.sub {
                        opacity: 1;
                        visibility: visible;

                        li {

                            ul.sub {
                                opacity: 0;   
                            } 
                        }  

                        li:hover {


                            ul.sub {
                                opacity: 1;
                                visibility: visible;
                                overflow: hidden;
                                z-index: 1;
                                left: rem(170px); 

                            } 
                        } 
                    }
                
            }

              .sub {
                visibility: hidden;
                width: rem(280px);
                position: absolute;
                padding:0;
                z-index:2000;
                left:rem(0px);
                top:rem(29px);
 				

                li {
                    height: auto;
                    float: none;
                    width: 100%;
                    border-top:rem(1px) solid rgba(75,75,74,0.5);
                    margin:rem(0px);
                    border-right: none; 
                   

                     a {
                        background: $medium;
                        padding:rem(12px) rem(20px);
                        text-align: left;
                        border-right: none; 
                        font-weight:400;

                        &:hover, &.active {
                            color:$primary;  
                            font-weight:600; 
                        }
                    }
                    
                }
            }

        }

	}

    @include breakpoint(giant) {
        top:rem(60px);
    }
}

// Navi ADD
//////////////////////////////
		.navi-add {


        li {

            &:hover {

                a {
                    text-decoration: underline;
                    color:$primary;
                }
            }

            &.active {

                a {
                    text-decoration: underline;
                    color:$primary;
                }
            }

            a {
                text-decoration: none;
                color:$primary;
            }

        }

@include breakpoint(tiny) {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            position: relative;
            padding-right: 5rem;
            transition: .6s;

		li {
			 display: inline;

            .datenschutzerklaerung{
                    border-right: rem(1px) solid $border;
                    padding-right:rem(20px);
                    margin-right:rem(20px);
                }
		}

}

	}

// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $secondary;
	display: flex;
	height: rem($menu-bar);
	justify-content: space-between;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;

	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);

		&:first-child {

			i {
				transform: rotate(120deg);
			}
		}

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
			flex-direction: row-reverse;
			font-size: rem(18px);

			&:before {
				@extend .fa-solid;
				@extend .fa-bars;
				margin-right: rem(10px);
			}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}
	}

	@include breakpoint(medium) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			
			&:before {
				@extend .fa-solid;
				@extend .fa-times;
			}

			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}

html.hidden-scroll {
    overflow: hidden;
}