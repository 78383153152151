// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	
	@include breakpoint(medium) {
		padding-top: 0;
	}
}

// CONTAINER-TOP
//////////////////////////////
.container-top{
	
	.branding {
		padding:rem(20px) rem(0px);
		text-decoration: none;
		display:table-cell;
		vertical-align: middle

	}	

	.bg-phone{
	    background: $secondary;
	    padding: rem(20px) rem(30px);
	    width:100%;
	    text-align:center;
	    height:auto;
	    z-index:500;

	    a{
	    	text-decoration: none;

	    	&:hover, &:active{
	    		text-decoration:underline;
	    		color:$primary;
	    	}
	    }
	}

@include breakpoint(tiny) {
		.bg-phone{
			text-align:left;
		}
}


@include breakpoint(medium) {
	position:fixed;
	height:rem(130px);
	background:$medium;
	z-index:999;
	width: 100%;
	display:block;

	.bg-phone{  
	    display:none;

	    right: rem(20px);
	    position:absolute; 
	    width:auto;
	    text-align:center;
	    padding: rem(20px) rem(20px) rem(20px) rem(35px);
	}

	.branding{
			float: left;
		}
	}

@include breakpoint(large) {
	.branding{
			padding:rem(25px) rem(0px);
		} 
}

@include breakpoint(giant) {
	.branding{
			padding:rem(30px) rem(0px);
		}
}
}


// HEADER
//////////////////////////////

.header {	
	position: relative;
	width: 100%;
	background: url(../images/index/slideshow/img01.jpg) no-repeat;
	height: rem(127px);
	background-size:cover;

.bg-phone{
			display:none;
		}


	@include breakpoint(tiny) {
		background: url(../images/index/slideshow/img01-tiny.jpg) no-repeat;
		height: rem(159px);
		background-size:cover;

		
	}

	@include breakpoint(small) {
		background: url(../images/index/slideshow/img01-small.jpg) no-repeat;
		height: rem(222px);
		background-size:cover;



	}

	@include breakpoint(medium) {
		background: url(../images/index/slideshow/img01-medium.jpg) no-repeat;
		height: rem(269px);
		background-size:cover;
		padding-top:rem(130px);

		.bg-phone{
	    display:block;
	    background: $secondary;
	    right: rem(20px);
	    position:absolute; 
	    width:auto;
	    text-align:center;
	    padding: rem(20px) rem(20px) rem(20px) rem(35px);
	    height:auto;
	    z-index:500;

	    a{
	    	text-decoration: none;

	    	&:hover, &:active{
	    		text-decoration:underline;
	    		color:$primary;
	    	}
	    }
	}

	}

	@include breakpoint(large) {
		background: url(../images/index/slideshow/img01-large.jpg) no-repeat;
		height: rem(348px);
		background-size:cover;

	}

	@include breakpoint(giant) {
		background: url(../images/index/slideshow/img01-giant.jpg) no-repeat;
		height: rem(411px);
		background-size:cover;

	}

	@include breakpoint(huge) {
		background: url(../images/index/slideshow/img01-huge.jpg) no-repeat;
		height: rem(632px);
		background-size:cover;
	}

}

	body {
		&:not(.index) {
		
		.header .bg-phone, .container-top .bg-phone{
			display:none;
		}

		.header{
			background:none;
			height: rem(0px);
		}
	}
}

// CONTAINER-SLIDESHOW
//////////////////////////////

.container-slideshow{	
	display:block;
	width:100%;
	z-index:1;
}

// SLIDESHOW
//////////////////////////////
#slideshow{
	display: block;
	z-index:1;	

	.slider-01{
		background: url(../images/index/slideshow/img01.jpg) center no-repeat;
		background-size:cover;

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img01-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img01-small.jpg) center  no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img01-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img01-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img01-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img01-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-02{
		background: url(../images/index/slideshow/img02.jpg) center no-repeat;
		background-size:cover;

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img02-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img02-small.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img02-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img02-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img02-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img02-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-03{
		background: url(../images/index/slideshow/img03.jpg) center no-repeat;
		background-size:cover;

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img03-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img03-small.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img03-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img03-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img03-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img03-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-04{
		background: url(../images/index/slideshow/img04.jpg) center no-repeat;
		background-size:cover;

		.img-link{
    	margin: rem(50px) rem(0px);
    	z-index:5000;
		}

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img04-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img04-small.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img04-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img04-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img04-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img04-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-05{
			background: url(../images/index/slideshow/img05.jpg) center no-repeat;
			background-size:cover;

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img05-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img05-small.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img05-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img05-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img05-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img05-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-06{
			background: url(../images/index/slideshow/img06.jpg) center no-repeat;
			background-size:cover;

		@include breakpoint(tiny) {
			background: url(../images/index/slideshow/img06-tiny.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(small) {
			background: url(../images/index/slideshow/img06-small.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(medium) {
			background: url(../images/index/slideshow/img06-medium.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(large) {
			background: url(../images/index/slideshow/img06-large.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(giant) {
			background: url(../images/index/slideshow/img06-giant.jpg) center no-repeat;
			background-size:cover;
		}

		@include breakpoint(huge) {
			background: url(../images/index/slideshow/img06-huge.jpg) center no-repeat;
			background-size:cover;
		}
	}

	.slider-01, .slider-02, .slider-03, .slider-04, .slider-05, .slider-06{
		width:100%;
		display: block;
		height: rem(127px);

		p{
			display:none;
		}

	@include breakpoint(tiny) {
		height: rem(159px);
	}

	@include breakpoint(small) {
		height: rem(222px);
	}

	@include breakpoint(medium) {
		height: rem(269px);
	}

	@include breakpoint(large) {
		height: rem(348px);
	}

	@include breakpoint(giant) {
		height: rem(411px);
	}

	@include breakpoint(huge) {
		height: rem(632px);
	}
}


		}



// TEASER
//////////////////////////////
.teaser{
	display:block;
	padding:rem(30px) rem(0px) rem(0px) rem(0px);

	ul{ 
		li{
			

    		&:before {
				content: none;
			}
   		}
	}

	p{
		margin-bottom:rem(27px);
	}		
    
    .btn {
    background: $mediumlight;
    border: none;
    color: $alert;
    font-size:rem(16px);
    cursor: pointer;
    display: table;
    font-weight:600;
    line-height: 1rem;
    padding: rem(25px) rem(0px) rem(0px) rem(0px);
    text-align: center;
    text-decoration: none;
    transition: 300ms all ease-in-out;
    border: rem(3px) solid $light;
    margin: rem(0px) auto;
    vertical-align: center;

    &:hover, &:active, &:focus {
        background: $alert;
        color: $light;
        border:rem(3px) solid $alert;
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}


	

@include breakpoint(tiny) {
	.btn{ 
		 	font-size:rem(22px);
		 }
		}

@include breakpoint(small) {
	.btn{ 
		 	font-size:rem(16px);
		 }
		}

	@include breakpoint(medium) {
		padding:rem(50px) rem(0px) rem(0px) rem(0px);
		margin-top:rem(175px);

		 .btn{ 
		 	font-size:rem(22px);
		 }

	}

	@include breakpoint(giant) {
		padding:rem(80px) rem(0px) rem(0px) rem(0px);

		.btn{
			    font-size: $h1-size;
		}
	}

}

body {
&:not(.index) {
	.teaser{

		@include breakpoint(medium) {
			top:rem(0px);
			}
		}
	}
}
// MAIN CONTENT
//////////////////////////////
body {
&:not(.index) {
	main{
		@include breakpoint(medium) {
		padding:rem(0px) rem(0px);
		}

		}
	}
}


main {
	display: block;
	padding:rem(30px) rem(0px);

@include breakpoint(medium) {
		padding:rem(50px) rem(0px);

		p{
			margin-bottom:rem(45px);
		}
	}

	@include breakpoint(giant) {
		padding:rem(80px) rem(0px);
	}

	ul, dl {
		@extend .styled-list;
	}

.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

.maps-contact {
		@extend .video-container;
			width:100%;
			height:auto;
			margin-bottom:rem(15px);

	@include breakpoint(small) {
		height:rem(250px);
	}

	@include breakpoint(large) {
		height:rem(320px);
		}

			}

}




 
.kontakt{
		dl {
			display: block;
			background: rgba($light, .5);
			border:rem(3px) solid $light;
  			width:100%;
			padding:rem(15px) rem(20px) rem(15px) rem(20px);

			dd, dt{
				background: none;
				font-weight:400;
			}

			@include breakpoint(small) {
				dd, dt{
				float: left;
				width:50%;
				}

				dd{
					padding-top:rem(20px);
				}
			}

			@include breakpoint(large) {
				height:rem(320px);
				display: table-cell;
				vertical-align: middle;

				dd, dt{
				float: none;
				width:auto;
			}

			dd{
					padding-top:rem(0px);
				}
			}
		}

		.anschrift{
			p{
				margin-bottom:rem(0px);
			}
		}

		}


// CONTACT-BOX
//////////////////////////////

.contact-box {
	display: block;
	padding:rem(50px) rem(0px) rem(30px) rem(0px);
	background: url(../images/index/bg-contact.jpg) no-repeat;
	background-size:cover;
	
	p, a{
		color: $medium;
	}

	.h3{
			font-size: $h6-size;
			}

	.btn {
	    background: $medium url(../images/icons/icon-li-btn.png) no-repeat center left;
	    border: none;
	    color: $primary;
	    border:none;

    &:hover, &:active, &:focus {
        background: $secondary url(../images/icons/icon-li-btn.png) no-repeat center left;
       	color: $primary;
        border:none;
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}



	@include breakpoint(medium) {
		padding:rem(50px) rem(0px);
		background: url(../images/index/bg-contact-medium.jpg) no-repeat;
		background-size:cover;
		height:auto;

		.h3{
			font-size: $h2-size;
			}


	}

	@include breakpoint(large) {
		padding:rem(80px) rem(0px);

		.box-left{
			padding-right:rem(25px);
			}

		.box-right{
			padding-left:rem(25px);
			}
	}

	@include breakpoint(huge) {
		height: rem(519px);
		background-size:cover;
		padding:rem(130px) rem(0px);
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	padding:rem(30px) rem(0px) rem(25px) rem(0px);
}

// ADDS
//////////////////////////////

body.cmsbackend #slideshow { 
	height: rem(0px);
	display:none;
}

body.cmsbackend .ewcms_form, body.cmsbackend .ewcms_iframe{
	z-index:10000;
}

body.cmsbackend .header { 
	disply:none;
	height: rem(0px);
}

body.nojs #slideshow {
	display:none;
	height: rem(0px);
}


body.nojs .header{
	position: relative;
	width: 100%;
	background: url(../images/index/slideshow/img01.jpg) no-repeat;
	height: rem(127px);
	background-size:cover;

	@include breakpoint(tiny) {
		background: url(../images/index/slideshow/img01-tiny.jpg) no-repeat;
		height: rem(159px);
		background-size:cover;
	}

	@include breakpoint(small) {
		background: url(../images/index/slideshow/img01-small.jpg) no-repeat;
		height: rem(222px);
		background-size:cover;

	}

	@include breakpoint(medium) {
		background: url(../images/index/slideshow/img01-medium.jpg) no-repeat;
		height: rem(269px);
		background-size:cover;
		padding-top:rem(175px);
	}

	@include breakpoint(large) {
		background: url(../images/index/slideshow/img01-large.jpg) no-repeat;
		height: rem(348px);
		background-size:cover;

	}

	@include breakpoint(giant) {
		background: url(../images/index/slideshow/img01-giant.jpg) no-repeat;
		height: rem(411px);
		background-size:cover;

	}

	@include breakpoint(huge) {
		background: url(../images/index/slideshow/img01-huge.jpg) no-repeat;
		height: rem(632px);
		background-size:cover;
	}
}

body.nojs .teaser{
	@include breakpoint(large){
		margin-top: rem(60px);}
}

body.nojs {
	&:not(.index) {
		.header{
		background:none;
		height: rem(0px);
		}
	}
}