/**
 * Accordion
 */
.accHandler {
    padding: rem(7px) rem(50px) rem(7px) rem($base-gap);
    background: $medium;
    position: relative;

    @include breakpoint(medium) {
        padding: rem(7px) rem($base-gap);    
    }

    &.active {
        cursor: pointer;

        &:before {
            display: inline-block;
        }
    }

    &.opened {

        &:before {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &:before {
        @extend .fa-solid;
        @extend .fa-plus;
        position: absolute;
        display: none;
        top: 50%;
        transform: translateY(-50%);
        right: rem($base-gap);
        font-size: rem($base-font-size);
        transition: all .3s ease-in-out;
    }
}

/**
 * Back to top
 */
.backToTop {
    @include hide-text();
    background: $dark;
    color: $light;
    text-decoration: none;
    position: fixed;
    bottom: 2%;
    right: 2%;
    height: rem(45px);
    opacity: .9;
    width: rem(45px);
    z-index: 890;

    &:before {
        @extend .fa-solid;
        @extend .fa-angle-up;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
    }

    &:hover, &:active, &:focus {
        color: $light;
        background: lighten($dark,10%);
    }
}

noscript {
    margin-top: rem(60px);
    display: block;

    input#noticebar {
        display: none;

        &:checked {
            
            ~ div {
                opacity: 1;
                visibility: visible;
            }

            ~ label {
                background: $light;
                color: $alert;

                &:before {
                    @extend .fa-times-circle;
                }
            }
        }
    }

    label {
        @include hide-text();
        background: $alert;
        bottom: rem(10px);
        color: $light;
        font-size: rem(25px);
        height: rem(45px);
        opacity: .9;
        position: fixed;
        right: rem(10px);
        text-decoration: none;
        width: rem(45px);
        z-index: 910;

        &:before {
            @extend .fa-solid;
            @extend .fa-exclamation-circle;
            display: inline-block;
            left: 50%;
            position: absolute;
            text-indent: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            cursor: pointer;
            background: darken($alert, 5%);
        }

        + div {
            opacity: 0;
            visibility: hidden;
            height: 100vh;
        }
    }
}

noscript label + div, .noticeBar {
    background: $alert;
    color: $light;
    display: flex;
    justify-content: space-around;
    left: 0;
    padding: rem(20px);
    position: fixed;
    text-align: center;
    top: 0;
    transition: .3s;
    width: 100%;
    z-index: 900 !important;

    span {
        align-self: center;
        font-size: rem(22px);
        line-height: rem(30px);
        margin: 0;
    }

    a {
        color: $light;

        &:hover {
            color: $light;
            text-decoration: none;
        }
    }
}

noscript div:first-child {
    display: none;
}

.breadcrumb li {
    list-style: none;
    float: left;

    &:before {
        content: '>';
        padding: 0 1rem;
    }

    &:first-child:before {
        content: "";
        padding: 0;
    }
}

/**
 * Debug mode
 */
%debug {
    height: 100%;
    overflow-y: scroll;
    background: $light;
    padding: 1%;
}

#log_container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    color: $dark;
    background: darken($light, 5%);
    padding: 2%;
    transition: all .5s ease-in-out;
    box-shadow: inset 0 0 rem(1px) rem(1px) rgba($alert, .5);
    z-index: 99999999;

    pre {
        @extend %debug;
    }

    .checkupXYZ {
        @extend %debug;
    }

    &:target {
        top: 0;
    }

    &.show {
        top: 0;
    }

    ul {
        list-style: inside square;
        
        ul {
            margin-left: 2rem;
        }

        h3 {
            display: inline-block;
        }

        li {
            padding: .5rem 0;
            border-bottom: rem(1px) solid darken($light, 5%);
            
            &:last-child {
                border: none;
            }
        }
    }

    h1 {
        text-align: center;
        padding-top: 2rem;
    }

    h2 {
        padding-bottom: .5rem;
        border-bottom: rem(1px) solid $medium;
        text-align: center;
        font-weight: normal;
        margin-top: 4rem;
    }

    h3 {
        font-weight: normal;
    }

    .checkup_pages {
        
        li {
            padding: 1rem;
        }

        .sub {
            margin-left: 2rem;
        }
    }

    > p {
        margin-bottom: 4rem;
    }

}

.checkup_pages > li:nth-child(odd) {
    background: #F4F4F4;
}


.checkupXYZ {
    width: rem(960px);
    margin: 0 auto;
    resize: horizontal;
}

.informatizer {
    background: #8DD45C;
    padding: 1%;
    position: fixed;
    top: 2%;
    right: 2%;
    display: block;
    color: $light;
    transition: all .5s ease-in-out;
    text-decoration: none;

    &:before {
        @extend .fa-solid;
        @extend .fa-exclamation-circle;
        margin-right: 1rem;
        font-size: em(24px);
        line-height: 1rem;
    }

    a {
        color: $light;
    }

}

.slider {
    overflow-x: hidden;
    /* width: 1000px; bei Bedarf anpassen, da hier 100% gewünscht sind, kann man die Angabe einsparen */
}
 
.sliderElements {
    width: 300%; /* Summe der Einzelbreiten der Slides */
    height: rem(320px);

    @include breakpoint(tiny) {
            max-height: rem(600px);
        }

    @include breakpoint(small) {
             width: 100%;
        }

    @include breakpoint(large) {
            height: auto;
        }
}
 
.sliderElements > li {
    float: left;
    width: 33.3333%; /* 100 durch Anzahl der Slides */
}
 
/* Clearfix für die Liste */
.sliderElements:after {
    content: ".";
    display: block;
    height: .1px;
    clear: both;
    visibility: hidden;
    font-size: 0;
    overflow: hidden;
}

.sliderElements,
.sliderElements figure,
.sliderControls {
    margin: 0;
}

 
.sliderElements:after {
    content: ".";
    display: block;
    height: .1px;
    clear: both;
    visibility: hidden;
    font-size: 0;
    overflow: hidden;
}
 
.cssSlider {
    overflow: hidden;
}
 
.sliderElements {
    list-style: none;
    position: relative;
    left: 0;
    margin-bottom: .8em;
    padding: 0;
    -webkit-transition: left .8s ease-in-out;
    -moz-transition: left .8s ease-in-out;
    -o-transition: left .8s ease-in-out;
    transition: left .8s ease-in-out;
}
 
.sliderElements > li {
    float: left;  
    position: relative;
}

#slide01:checked ~ .sliderElements {
    left: 0%;
}
 
#slide02:checked ~ .sliderElements {
    left: -100%;
}
 
#slide03:checked ~ .sliderElements {
    left: -200%;
}
 
 
/* Bildunterschrift auf dem Bild positionieren */
.sliderElements figcaption {
    display: block;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 1em;
    padding: .4em;
    background: rgba(0,0,0,.5);
}
 
/* Bilder responsive */
.sliderElements img {
    width: 100%;
    height: auto;
}
 
 
/* inputs aus dem Blickfeld schieben */
.cssSlider input {
    position: absolute;
    left: -99999px;
}
 
/* mittige Ausrichtung der Controls - funktioniert im Zusammenspiel mit inline-block */
.sliderControls {
    text-align: center;

    @include breakpoint(small) {
        display:none;
    }
}
 
/* Controls nebeneinander bringen */
.sliderControls li {
    display: inline-block;
}
 
/* Controls auf einheitliche Maße bringen und die Ecken abrunden */
.sliderControls label {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    display: block;
    cursor: pointer;
    background: $secondary;
    color: $secondary;
}
 
/* Attributselektor und indirekter Nachfahrkombinator zum ansteuern der labels */
.sliderControls label:hover,
#slide01:checked ~ .sliderControls label[for="slide01"],
#slide02:checked ~ .sliderControls label[for="slide02"],
#slide03:checked ~ .sliderControls label[for="slide03"]{
    background: $primary;
    color: $primary;
}