// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

// @include font-face(
//  $name: "icomoon",
//  $file: "icomoon-webfont",
//  $weight: 400,
//  $style: normal,
//  $type: woff
// );

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);


@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: local('Signika Light'), local('Signika-Light'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE_oEE3dJag.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} 


@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: local('Signika SemiBold'), local('Signika-SemiBold'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE44CE3dJag.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} 
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: local('Signika Light'), local('Signika-Light'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE_oEE3dJbA.woff) format('woff');
}
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: local('Signika SemiBold'), local('Signika-SemiBold'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE44CE3dJbA.woff) format('woff');
}
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: local('Signika Light'), local('Signika-Light'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE_oEE3dJbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: local('Signika SemiBold'), local('Signika-SemiBold'), url(/_fgf/s/signika/v8/vEFU2_JTCgwQ5ejvE44CE3dJbw.ttf) format('truetype');
}