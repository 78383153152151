.highlight {
    color: $secondary;
}



.after::after {
    content: "\A";
    white-space: pre;

    @include breakpoint(tiny) {
        content: none;
        white-space: none;
        }
}

.border-left{
     margin-left:rem(20px);

    @include breakpoint(tiny) {
        padding:rem(0px) rem(20px);
        border-left: rem(1px) solid $primary;
    }
}

.img{
    img{
        border:rem(3px) solid $light;
         overflow:hidden;
    }
}

body:not(.cmsbackend) .img-projekt{
     width:100%;
    height:rem(230px);
    overflow:hidden;
    position:relative;

    img{
        height: 100%;
        transform: translate(0%,-45%);
     }

    @include breakpoint(small) {
    height:rem(360px);
   }
}

.img-kontakt{
    border:rem(3px) solid $light;
     overflow:hidden;

   img{    
         width: 100% !important;
        object-fit: cover;
        object-position: top right;

    @include breakpoint(small) {
        height:rem(245px);
     }

        @include breakpoint(large) {
        height:rem(315px);
     }
    }
}

.img-kontakt-bottom{
   overflow:hidden; 
   border:rem(3px) solid $light;

   img{
        width: 100% !important;
    object-fit: cover;
    object-position: top center;

    @include breakpoint(small) {
        height:rem(180px);
     }

      @include breakpoint(medium) {
        height:rem(220px);
     }

      @include breakpoint(large) {
        height:rem(250px);
     }

        @include breakpoint(giant) {
        height:rem(315px);
     }
    }
}

.img-box{
    display:none;
       
    @include breakpoint(small) {
        display:block;
       }
}



body:not(.cmsbackend) .border{
    border:rem(3px) solid $light;
     margin-bottom: rem(30px);
}


.descr{ 
     text-align:center;
    margin-top: rem(-23px);
    margin-bottom: rem(35px);

        @include breakpoint(tiny) {
            font-size:rem(14px);
        }

        @include breakpoint(medium) {
            font-size:$base-font-size;
        }

}

.bg-light{
    background:$light;
    padding: rem(35px) rem(30px);

        h2{
            margin-bottom:rem(10px);
        }

        p{
            margin-bottom:rem(2px);
        }
            

        .projekt{
            p{
            margin-bottom:rem(15px);
            }
        }
}

.spacer{
    margin-top: rem(15px);

    @include breakpoint(giant) {
         margin-top: rem(0px);
    }
}



.btn {
        background: $secondary url(../images/icons/icon-li-btn.png) 0% 50% no-repeat;
        border: none;
        color: $dark;
        cursor: pointer;
        display: table;
        line-height: 1rem;
        font-family: $main-font;
        font-size: rem(16px);
        margin: 0 0 1.75rem;
        padding: rem(25px) rem(45px) rem(25px) rem(60px);
        text-align: center;
        transition: .3s;
        text-decoration:none;

        &:hover, &:focus {
             background: $dark url(../images/icons/icon-li-btn-light.png) 0% 50%  no-repeat;
            color: $medium;
            text-decoration:none;
        }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }

    @include breakpoint(tiny) {
                font-size: rem(22px);
    }

      @include breakpoint(small) {
                font-size: rem(18px);
      }

      @include breakpoint(medium) {
                font-size: rem(22px);
      }
}


.clear {
    clear: both;
    float: none;
}

.clearfix {
    @include pie-clearfix;
}

.hidden {
    display: none;
}

.hide-text {
    @include hide-text();
    display: inline-block;
    overflow: hidden;
    width: 0;
}

.float-left {
    float: left;
}

img.float-left {
    margin-right: 1rem;
}

.float-right {
    float: right;
}

img.float-right {
    margin-left: 1rem;
}

.fluid {
    height: auto;
    width: 100%;
}

.non-fluid {
    width: auto!important;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.wide {
    width: 100%;
    display: block;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

/* 16:9 video resolutions */
.video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        border:rem(3px) solid $light;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.lightbox-inline {

}

.lightbox-external {

}

/* default colorbox hover */
.lightbox-image {
    @extend .margin-bottom;
    display: table;
    position: relative;
    text-decoration: none;
    width: 100%;

    &[href*=qr] {
        width: auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        @extend %animated-transform;
        pointer-events: none;
    }

    &:before {
        bottom: 0;
        color: rgba($light, .75);
        content: '+';
        display: block;
        font-family: sans-serif;
        font-size: 2rem;
        height: 2em;
        line-height: 1.8;
        position: absolute;
        right: 0;
        text-align: center;
        text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, .8);
        transform: translate3d(0, 0, 0);
        width: 2em;
        z-index: 1;
        top:rem(0px);
    }

    &:after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &:hover, &:focus {

        &:before {
            bottom: 50%;
            font-size: 3rem;
            height: 1.5em;
            line-height: 1.5;
            right: 50%;
            transform: translate3d(50%, 50%, 0);
            width: 1.5em;
            top:30%;
        }

        &:after {
            opacity: .5;
        }
    }
}

body {
    &:before {
        $content: 'default:' + $bp-context;
        width: str_length($content) * 6pt + $base-gap * 2;

        @each $point, $width in $breakpoints {
            $content: $content + '....' + $point + ':' + $width;

            @include breakpoint($point) {
                width: str_length($content) * 6pt + $base-gap * 2;
            }
        }

        content: '#{$content}';
        display: none !important; /* Prevent from displaying. */
    }

    &.devmode:before, &.debug:before {
        background: $alert;
        border-color: $alert;
        border-style: solid;
        border-width: 0 rem($base-gap);
        color: $light;
        display: block !important;
        font-family: Courier;
        font-size: 10pt;
        left: 0;
        line-height: 2.5;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        white-space: nowrap;
        z-index: 99999;
    }
}

// Animations
%animated-transform {
    transition: .3s;
}

/**
 * jQuery content slideshow
 * @name showslide
 * @author Dominik Kressler
 * @version 1.1.3
 * @category jQuery plugin
 * @copyright (c) 2013
 *
 * Changelog:
 *
 * SEE JS FILE
*/

%controls-pseudo {
    font-family: $icon-font;
    text-indent: 0;
    display: block;
    color: $light;
    font-size: rem(24px);
    text-align: center;
    line-height: 2rem;
}


/**
 * NAVIGATION
 */
.showslideController {
    cursor: pointer;
    list-style: none;
    z-index: 20;
    transition: all 500ms ease-in-out;
}

a.showslideController {
    background-color: rgba($dark, .2);
    border-radius: rem(4px);
    display: block;
    height: rem(30px);
    position: absolute;
    @include hide-text();
    padding:rem(3px);
    opacity: .6;
    width: rem(30px);

    &.showslidePlay {
        bottom: rem($base-gap);
        left: 50%;
        margin-left: rem(-15px);

        &:before {
            @extend .fa-pause;
            @extend %controls-pseudo;
        }

        &.true:before {
            @extend .fa-play;
            @extend %controls-pseudo;
        }
    }

    &.showslidePrev, &.showslideNext {
        top: 50%;
        margin-top: rem(-15px);
    }

    &.showslidePrev {
        left: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend .fa-chevron-left;
        }
    }

    &.showslideNext {
        right: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend .fa-chevron-right;
        }
    }

    &:hover {
        background-color: rgba($dark, .8);
        opacity:1;
    }
}

.showslideSelect {
    z-index: 20;
    position: absolute;
    bottom: rem($base-gap / 2);
    margin:auto;
    text-align: center;
    width:100%;

    li {
        display: inline-block;
        @include hide-text();
        width: rem(14px);
        height: rem(14px);
        border-radius: 50%;
        background: $light;
        opacity: 0.5;
        margin-right: rem(15px);
    }
    &.thumbnails li {
        width: rem(64px);
        height: rem(64px);
        background-size: cover;
        border: 3px solid $primary;
    }
    li {
        &.active, &:hover {
            opacity: 1;
        }
    }
}

/**
* GLOBALS
**/

.slider {
    overflow: hidden;
    position: relative;
    width: 100%;

    /**
     * SPEED SETTING
     */

    > .animate {
        transition-duration: 1000ms;
    }

    /**
    * SPECIAL EASINGS
    * FOR MORE EASINGS: http://matthewlein.com/ceaser/
    **/
    &.horizontal > .animate {
        transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
        /* easeInOutQuad */
    }
    &.vertical > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.fade > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.flip > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffOut > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffIn > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.zoomOut > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.zoomIn > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }

    .slide {
        z-index: 1;

        &:not(.active) {
            z-index: 1;
        }
        &.active {
            float: left;
            position: relative;
            z-index: 10;
        }

        img {
            height:auto;
            width:100%;
        }

        width: 100%;
        height: 100%;
        display: block;
        position: absolute;

        /**
        * ELEMENTS WITH data-caption-ATTRIBUTES
        **/
        &[data-caption]:after {
            content: attr(data-caption);
            background: rgba(255, 255, 255, 0.5);
            left: 0;
            color: #000;
            display: block;
            padding: 1em;
            position: absolute;
            width: 100%;
            top: -100%;
        }
        &.active[data-caption]:after {
            top: 0;
        }
        &[data-caption]:after {
            transition-property: all;
        }
    }

    /**
    * TRANSITIONS
    **/
    &.fade > .animate, &.horizontal > .animate, &.vertical > .animate, &.zoomOut > .animate, &.zoomIn > .animate, &.puffOut > .animate, &.puffIn > .animate {
        transition-property: all;
    }

    /**
    * FADE
    **/
    &.fade .slide {
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }

    /**
    * HORIZONTAL
    **/
    &.horizontal .slide {
        &.active {
            left: 0;
            z-index: 10;
        }
        &.right {
            left: 100%;
            z-index: 1;
        }
        &.left {
            left: -100%;
            z-index: 1;
        }
    }

    /**
     * VERTICAL
     */
    &.vertical {
        .active {
            top: 0;
            z-index: 10;
        }
        .left {
            top: -100%;
            z-index: 1;
        }
        .right {
            top: 100%;
            z-index: 1;
        }
    }

    /**
     * FLIP
     */
    &.flip {
        > .animate {
            transition: all 2000ms ease-in-out;
        }
        overflow: visible;
        .left {
            opacity: 0;
            transform: perspective(800px) rotateX(-180deg) translateZ(300px);
        }
        .right {
            opacity: 0;
            transform: perspective(800px) rotateX(180deg) translateZ(300px);
        }
        .active {
            opacity: 1;
            transform: perspective(0) rotateX(0) translateZ(0);
        }
    }

    /**
     * puffOUT
     */
    &.puffOut {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(0.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * puffIN
     */
    &.puffIn {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(0.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomOUT
     */
    &.zoomOut {
        .left {
            opacity: 0;
            transform: scale(0.2);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomIN
     */
    &.zoomIn {
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }
}


